<script setup>
import {TAlert, TButton, TCard} from "@variantjs/vue";
import VideoPlayIcon from "@/components/icons/VideoPlayIcon.vue";
import {defineProps, defineEmits} from "vue"

const props = defineProps({
  patientName: String,
  recordings: Array,
  callDone: Boolean,
})

defineEmits([
  'joinRoom',
  'reportSuccess',
  'reportFailure',
])
const  formatDuration = (durationInSeconds) => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${seconds}s`.trim();
}
console.log(props.recordings)
</script>

<template>
  <div class="grid grid-cols-1 p-6 md:grid-cols-2">
    <div class="flex justify-center p-2">
      <div class="mt-5" id="test-video" style="min-height: 180px; min-width: 180px; background-color: black;"></div>
    </div>
    <div class="w-full p-2 text-center mt-5">
      <div class="p-6">
        <p class="w-full mb-3 text-3xl"> {{ $t("video.ready_to_start")}}</p>
        <t-card class="mt-6">
          <P class="p-3">
            {{ $t('video.patient_name') }} : {{ patientName }}
          </P>
        </t-card>
        <div class="mt-6">
          <t-alert variant="warning" :dismissible="false">
            {{ $t('video.recording_disclaimer') }}
          </t-alert>
        </div>
        <t-button class="w-full mt-6" @click="$emit('joinRoom')">
          <p class="flex items-center my-1 text-sm font-semibold leading-9 text-center text-white sm:text-base">
            <VideoPlayIcon />
            <span class="ml-3">
                {{ $t('video.join') }}
              </span>
          </p>
        </t-button>
      </div>
    </div>
    <t-card v-if="recordings && recordings.length > 0" class="mt-3">
      <div class="focus:outline-none text-lg leading-5 text-gray-800 border-b border-gray-100 p-3 rounded-t">{{ $t('calls_list') }}</div>
      <div class="p-3 flex-1">
        <div class="overflow-x-auto">
          <table class="table-auto w-full whitespace-nowrap">
            <thead>
            <tr class="text-gray-500">

              <th class="text-center">{{ $t('stated_at') }}</th>
              <th class="text-center">{{ $t('call_duration') }}</th>
              <th class="text-center">{{ $t('call_link') }}</th>
            </tr>
            </thead>
            <tbody class="w-full">
            <tr
                v-for="(recording, recordingUndex) in recordings"
                :key="recordingUndex"
                class="h-12 text-sm leading-none text-gray-800 border-b border-gray-100"
            >
              <td tabindex="0" class="focus:outline-none px-2 flex-grow text-center">
                {{ $d(recording.createdAt, 'long') }}
              </td>
              <td tabindex="0" class="focus:outline-none px-2 flex-grow text-center">
                {{ formatDuration(recording.duration) }}
              </td>
              <td tabindex="0" class="focus:outline-none px-2 flex-grow text-center">
                <audio :id="recordingUndex" controls preload="auto">
                  <source :src="recording.url" type="audio/mpeg">
                  Your browser does not support the audio tag.
                </audio>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </t-card>
  </div>
</template>